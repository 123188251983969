import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
const { htmlToText } = require("html-to-text")
const htmlToTextEx = (content, length) => {
  let text = htmlToText(content, {
    selectors: [
      { selector: 'img', format: 'skip' },
      { selector: 'a', options: { ignoreHref: true } },
    ]
  })
  text = text.replace(/\n/g, " ")
  text = text.replace(/ +/g, " ")
  if (text.length > length) {
    text = text.substr(0, length) + "..."
  }
  return text
}

const SearchEngineOptimization = ({ pagetitle, pagedesc, pagepath, hero }) => {
    const data = useStaticQuery(graphql`
        query {
            allMicrocmsSite {
                edges {
                  node {
                    id
                    hero {
                      url
                      height
                      width
                    }
                    title
                    desc
                    siteUrl
                    lang
                    locale
                  }
                }
              }
        }
    `)

    const siteMetadata = data.allMicrocmsSite.edges[0].node

    const title = pagetitle ? `${pagetitle} | ${siteMetadata.title}` : `${siteMetadata.title}`
    const desc = pagedesc || htmlToTextEx(siteMetadata.desc)
    const url = pagepath ? `${siteMetadata.siteUrl}${pagepath}` : `${siteMetadata.siteUrl}`
    const _hero = hero ? hero : siteMetadata.hero
    const w = _hero.width > 480 ? 480 : _hero.width
    const h = _hero.width > 480 ? _hero.height / _hero.width * 480: _hero.height

    return (
        <Helmet>
            <html lang={siteMetadata.lang} />
            <title>{title}</title>
            <meta name="description" content={desc} />
            <link rel="canonical" href={url} />

            <meta property="og:site_name" content={siteMetadata.title} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={desc} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content={siteMetadata.locale} />

            <meta property="og:image" content={`${_hero.url}?w=${w}&h=${h}`} />
            <meta property="og:image:width" content={w} />
            <meta property="og:image:height" content={h} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta name="google-site-verification" content="fHHq8vW0C3VyrJ3G4oqbx2NCySXlM3yb7LqDkk-4Bok" />

        </Helmet>
    )
}
export default SearchEngineOptimization

