import * as React from 'react';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Copyright from './Copyright';

const Layout = ({ siteMetadata, categories, hideCategory, children}) => {
  return (
    <React.Fragment>
      <Container component="section" maxWidth="lg">
        <Header title={siteMetadata.title} categories={categories} hideCategory={hideCategory} />
        {children}
        <footer>
          <Copyright title={siteMetadata.title} url={siteMetadata.siteUrl} />
        </footer>
      </Container>
    </React.Fragment>
  )
}

export default Layout
